<template>
    <section class="login-booking-area mht">
        <div class="container">
            <div class="row">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <div class="login-booking">
                        <div class="login-content">
                            
                            <div id="tab-2" class="content-tab" style="display: 'block'">
                                <div class="register-form">
                                    <form  method="get" accept-charset="utf-8" @submit.prevent="registerUser()">
                                        <div class="one-half pass">
                                            <label for="pass">Password</label>
                                            <input type="password" name="pass" autocomplete="off" id="pass" v-model.trim="$v.regPassword.$model" :class="{custErrorBorder: $v.regPassword.$error}">
                                            <div class="custError" v-if="$v.regPassword.$error && !$v.regPassword.required">Field is required</div>
                                            <div class="custError" v-if="!$v.regPassword.minLength">Password must have at least {{$v.regPassword.$params.minLength.min}} letters.</div>
                                        </div>
                                        <div class="one-half re-pass">
                                            <label for="re-pass">Repeat Password</label>
                                            <input type="password" name="phone" id="re-pass" v-model.trim="$v.regRepeatPassword.$model" :class="{custErrorBorder: $v.regRepeatPassword.$error}">
                                            <div class="custError" v-if="!$v.regRepeatPassword.sameAsRegPassword">Passwords must be identical.</div>
                                        </div>
                                        <div class="one-half btn-submit">
                                            <button type="submit">SUBMIT</button>
                                        </div>
                                        <div class="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>
    </section>
</template>

<script>
import { required, minLength, maxLength, sameAs, email, numeric } from 'vuelidate/lib/validators';
import { request } from "../utils/api";
import {mapGetters} from "vuex";

export default {
    data(){
        return {
            regPassword: '',
            regRepeatPassword: '',
            token: this.$route.query.email
        }
    },
    computed:{
        ...mapGetters(['isLoggedIn'])
    },
    validations: {
        regPassword: {
            required,
            minLength: minLength(6)
        },
        regRepeatPassword: {
            sameAsRegPassword: sameAs('regPassword')
        }
        
    },
    created(){
        if(this.isLoggedIn){
            this.$router.push('/');
            return;
        }
        console.log('token', this.token)
    },
    methods:{
        async registerUser(){
            this.$v.$touch();
			if (this.$v.$anyError) {
                return;
            }

            const resp = await request({
                method: 'post',
                url: `/api/v1/user/resetpassword`,
                data: {
                        token: this.token,
                        password: this.regPassword,
                        confirmPassword: this.regRepeatPassword
                    }
            })
            if (resp.remote === "success") {
                this.$limotoast.success('Password updated');
                this.$router.push('/login');
            }else {
                this.$limotoast.error(resp.errors);
            }
        },
        
    },
}
</script>

<style>

</style>